import React from "react"
import styled from "styled-components"
import {
  CustomValidationMessage,
  RequiredTriangle,
  ControlLabel,
  FormRow,
  FormTwoCol,
  FormCol,
  Field,
  StepGrid,
  StepLeftGrid,
  StepRightGrid
} from "../FormStyle"
import { StepWrapper, HalfStep } from "../Section"
import StepVerticalVar from "../Icons/StepVerticalVar"
import HalfStepBar from "../Icons/HalfStepBar"

const CustomRequired = styled(CustomValidationMessage)`  
  width: fit-content !important;
`

class Step1 extends React.Component {
  render() {
    const {
      fNameValue,
      fNameRequire,
      fNameInputPattern,
      lNameRequire,
      lNameInputPattern,
      lNameValue,
      ssnRequire,
      ssnValue,
      ssnInputPattern,
      birthRequire,
      phoneRequire,
      phoneInputPattern,
      phoneValue,
      mobileRequire,
      mobileInputPattern,
      mobileValue,
      emailRequire,
      emailValue,
      emailInputPattern,
      birthInputValue,
    } = this.props
    if (this.props.currentStep !== 1) {
      // Prop: The current step
      return null
    }
    return (
      <StepGrid>
        <StepLeftGrid>
          <StepWrapper color="#676B79">
            <div>
              <strong className="step-number" style={{ color: "#ED602B" }}>1</strong>
              <span className='step-label' style={{ color: "#ED602B" }}>STEP</span>
              <HalfStep><HalfStepBar fill="#ED602B" /></HalfStep>
              <StepVerticalVar fill="#010920" />
            </div>
            <div>
              <strong className="step-number">2</strong>
              <span className='step-label'>STEP</span>
              <StepVerticalVar fill="#010920" />
            </div>
            <div>
              <strong className="step-number">3</strong>
              <span className='step-label'>STEP</span>
              <StepVerticalVar fill="#010920" />
            </div>
            <div>
              <strong className="step-number">4</strong>
              <span className='step-label'>STEP</span>
            </div>
          </StepWrapper>
        </StepLeftGrid>
        <StepRightGrid>
          <FormRow>
            <FormTwoCol>
              <Field>
                <ControlLabel htmlFor="first_name">First Name*</ControlLabel>
                <input
                  className="form-control bg-transparent"
                  type="text"
                  name="first_name"
                  placeholder="Please Enter Your First Name"
                  id="first_name"
                  onChange={this.props.handleChange}
                  value={fNameValue}
                  pattern="[A-Za-z\s]+$"
                />
                <CustomRequired
                  display={fNameInputPattern === "" ? "none" : "block"}
                  width="fit-content"
                >
                  <RequiredTriangle />
                  <span>{fNameInputPattern}</span>
                </CustomRequired>
                <CustomRequired display={fNameRequire ? "block" : "none"}>
                  <RequiredTriangle />
                  <span>Please input this field</span>
                </CustomRequired>
              </Field>
            </FormTwoCol>
            <FormTwoCol>
              <Field>
                <ControlLabel htmlFor="last_name">Last Name*</ControlLabel>
                <input
                  className="form-control bg-transparent"
                  type="text"
                  name="last_name"
                  placeholder="Please Enter Your Last Name"
                  id="last_name"
                  onChange={this.props.handleChange}
                  value={lNameValue}
                  pattern="[A-Za-z\s]+$"
                />
                <CustomRequired
                  display={lNameInputPattern === "" ? "none" : "block"}
                  width="200px"
                >
                  <RequiredTriangle />
                  <span>{lNameInputPattern}</span>
                </CustomRequired>
                <CustomRequired display={lNameRequire ? "block" : "none"}>
                  <RequiredTriangle />
                  <span>Please input this field</span>
                </CustomRequired>
              </Field>
            </FormTwoCol>
            <FormTwoCol>
              <Field>
                <ControlLabel htmlFor="ssn">Social Security Number(SSN)*</ControlLabel>
                <input
                  className="form-control bg-transparent"
                  type="text"
                  name="ssn"
                  placeholder="Please Enter Your SSN"
                  id="ssn"
                  onChange={this.props.handleChange}
                  value={ssnValue}
                  pattern="[0-9]+$"
                />
                <CustomRequired
                  display={ssnInputPattern === "" ? "none" : "block"}
                  width="200px"
                >
                  <RequiredTriangle />
                  <span>{ssnInputPattern}</span>
                </CustomRequired>
                <CustomRequired display={ssnRequire ? "block" : "none"}>
                  <RequiredTriangle />
                  <span>Please input this field</span>
                </CustomRequired>
              </Field>
            </FormTwoCol>
            <FormTwoCol>
              <Field>
                <ControlLabel htmlFor="birth">Date of Birth*</ControlLabel>
                <input
                  className="form-control bg-transparent"
                  type={this.props.dateType}
                  name="birth"
                  placeholder="Tell us your Date of Birth"
                  id="birth"
                  onChange={this.props.handleChange}
                  onFocus={this.props.onFocus}
                  value={birthInputValue}
                />
                <CustomRequired display={birthRequire ? "block" : "none"}>
                  <RequiredTriangle />
                  <span>Please input this field</span>
                </CustomRequired>
              </Field>
            </FormTwoCol>
            <FormTwoCol>
              <Field>
                <ControlLabel htmlFor="phone_no">Telephone Number*</ControlLabel>
                <input
                  className="form-control bg-transparent"
                  type="text"
                  name="phone_no"
                  placeholder="Tell us your Home Number"
                  id="phone_no"
                  onChange={this.props.handleChange}
                  value={phoneValue}
                  pattern="^[0-9]{10}$"
                />
                <CustomRequired
                  display={phoneInputPattern === "" ? "none" : "block"}
                  width="200px"
                >
                  <RequiredTriangle />
                  <span>{phoneInputPattern}</span>
                </CustomRequired>
                <CustomRequired display={phoneRequire ? "block" : "none"}>
                  <RequiredTriangle />
                  <span>Please input this field</span>
                </CustomRequired>
              </Field>
            </FormTwoCol>
            <FormTwoCol>
              <Field>
                <ControlLabel htmlFor="mobile_no">Cell Number*</ControlLabel>
                <input
                  className="form-control bg-transparent"
                  type="text"
                  name="mobile_no"
                  placeholder="Tell us your Personal Number"
                  id="mobile_no"
                  onChange={this.props.handleChange}
                  value={mobileValue}
                  pattern="^[0-9]{10}$"
                />
                <CustomRequired
                  display={mobileInputPattern === "" ? "none" : "block"}
                  width="200px"
                >
                  <RequiredTriangle />
                  <span>{mobileInputPattern}</span>
                </CustomRequired>
                <CustomRequired display={mobileRequire ? "block" : "none"}>
                  <RequiredTriangle />
                  <span>Please input this field</span>
                </CustomRequired>
              </Field>
            </FormTwoCol>
            <FormTwoCol>
              <Field>
                <ControlLabel htmlFor="email">Email Address*</ControlLabel>
                <input
                  className="form-control bg-transparent"
                  type="email"
                  name="email"
                  placeholder="Please Enter Your Email Address"
                  id="email"
                  onChange={this.props.handleChange}
                  value={emailValue}
                  pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
                  required
                />
                <CustomRequired
                  display={emailInputPattern === "" ? "none" : "block"}
                  width="200px"
                >
                  <RequiredTriangle />
                  <span>{emailInputPattern}</span>
                </CustomRequired>
                <CustomRequired display={emailRequire ? "block" : "none"}>
                  <RequiredTriangle />
                  <span>Please input this field</span>
                </CustomRequired>
              </Field>
            </FormTwoCol>
          </FormRow>
        </StepRightGrid>
      </StepGrid>
    )
  }
}

export default Step1
